// https://insights.algolia.io/1/events?x-algolia-application-id={YourApplicationId}&x-algolia-api-key={YourAPIKey}
// GATSBY_ALGOLIA_SEARCH_KEY=b41330c356bb747bfb5d946d555e94e5
// GATSBY_ALGOLIA_APP_ID=B4GHL3GSHD

import { useMemo } from 'react'
import { getCookie } from '~/components/impact-radius'
import { useCustomerContext } from '~/context/customer-context'
import algoliasearch from 'search-insights'
import { useCartContext } from '~/context/cart-context'
import { forEach } from 'lodash'

export const useAlgoliaTracking = () => {
  const algoliaBaseUrl = 'https://insights.algolia.io/1/events'
  const algoliaAppId = 'B4GHL3GSHD'
  const algoliaApiKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY
  const algoliaIndexName = 'products'
  const ADD_TO_CART = 'Product Added to Cart'
  const SENT_CART_COMBINATION = 'Cart Combination Sent'

  const { items } = useCartContext()

  const currentCartObjectIDs = useMemo(() => {
    if (items.length) {
      return items.map(
        item =>
          item.id
            .replace('gid://shopify/CheckoutLineItem/', '')
            .replace('gid://shopify/ProductVariant/', '')
            .split('?')[0]
      )
    }
    return []
  }, [items])

  const { auth } = useCustomerContext()

  const queryID = useMemo(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('queryID') ?? ''
    }

    return ''
  }, [])

  const userToken = useMemo(() => {
    if (auth) {
      return auth.accessToken
    }
    return getCookie('_ALGOLIA')
  }, [auth])

  const trackAlgoliaEvent = (event: any) => {
    if (algoliaAppId && algoliaApiKey) {
      fetch(algoliaBaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-algolia-application-id': algoliaAppId,
          'x-algolia-api-key': algoliaApiKey,
        },
        body: JSON.stringify(event),
      })
    }
  }

  const mountAlgoliaData = (objectIDs: string[], eventName?: string) => {
    const algoliaData = {
      index: algoliaIndexName,
      eventName: eventName ?? ADD_TO_CART,
      userToken: userToken,
      objectIDs: objectIDs,
      queryID: queryID,
    }

    return algoliaData
  }

  const algoliaConversionEvent = (objectIDs: string[]) => {
    const mergedObjectIDs = Array.from(new Set([...currentCartObjectIDs, ...objectIDs]))
    const algoliaData = mountAlgoliaData(mergedObjectIDs, SENT_CART_COMBINATION)

    forEach(mergedObjectIDs, (value, key) => {
      const restIds = mergedObjectIDs.filter(id => id !== value)

      if (!restIds.length) return

      forEach(restIds, (restValue, restKey) => {
        const prodIds = [value, restValue]
        const innnerData = mountAlgoliaData(prodIds, SENT_CART_COMBINATION)

        algoliasearch('convertedObjectIDsAfterSearch', innnerData)
      })
    })

    // algoliasearch('convertedObjectIDsAfterSearch', algoliaData)
  }

  const algoliaConversionEventSingle = (objectIDs: string[]) => {
    const mergedObjectIDs = Array.from(new Set([...currentCartObjectIDs, ...objectIDs]))
    const algoliaData = mountAlgoliaData(mergedObjectIDs)

    algoliasearch('convertedObjectIDsAfterSearch', algoliaData)
  }

  return {
    algoliaConversionEvent,
    algoliaConversionEventSingle,
    mountAlgoliaData,
  }
}
